import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import FieldToolbar from './shared/Toolbar';
import TouchScreenToolbar from './shared/TouchScreenToolbar';
import eventEmitter from '../../../../core/components/App/shared/event-emitter';
import { COMMON, EVENTS } from '../../../../core/constants';
import { FIELD } from '../../../../constants';
import { isFilledArray } from '../../../../core/utils/common-utils';
import getFieldCoordinates from '../../../../utils/canvas/get-field-coordinate';

import styles from './Field.module.scss';

const { REM } = COMMON;
const { CANVAS_SIZE } = FIELD;
const { RESIZE } = EVENTS;

const Field = ({
  isGameCompleted,
  matrix,
  selectedValue,
  squares,
  moveSquares,
}) => {
  const initialRef = {
    clientHeight: 0,
    offsetHeight: 0,
    offsetWidth: 0,
  };
  const field = useRef(initialRef);
  const toolbar = useRef(initialRef);
  const touchScreenToolbar = useRef(null);
  const content = useRef(initialRef);
  const canvas = useRef(null);
  const eventXY = useRef({ x: 0, y: 0 });
  const [cellsInRow, setCellsInRow] = useState(0);

  const handleResize = () => {
    const screenHeight = document.body.offsetHeight;
    const contentTop = content.current.getBoundingClientRect().top;
    const contentHeight = screenHeight - contentTop - REM;
    if (field.current && toolbar.current && content.current) {
      const toolbarWidth = toolbar.current.offsetWidth;
      const size = Math.min(toolbarWidth, contentHeight);
      setCellsInRow(size);
    }
  };

  const renderSquares = useCallback(() => {
    const context = canvas.current.getContext('2d');
    context.clearRect(0, 0, CANVAS_SIZE, CANVAS_SIZE);
    let isMoving = false;
    squares.forEach(square => {
      square.setProps({ isSelected: square.props.value === selectedValue });
      square.render({ context, matrix });
      isMoving = square.props.isMoving || isMoving;
    });
    if (isMoving) {
      requestAnimationFrame(renderSquares);
    }
  }, [matrix, selectedValue, squares]);

  useEffect(() => {
    eventEmitter.on(RESIZE, handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [isGameCompleted]);

  useEffect(() => {
    if (isFilledArray(squares)) {
      requestAnimationFrame(renderSquares);
    }
  }, [squares, selectedValue, renderSquares]);

  const handleMouseDown = e => {
    const { clientX, clientY } = e;
    if (clientX === eventXY.current.x && clientY === eventXY.current.y) {
      return;
    }
    eventXY.current = { x: clientX, y: clientY };
    const { x, y } = getFieldCoordinates(
      clientX,
      clientY,
      canvas.current,
      matrix,
    );
    moveSquares(x, y);
  };

  const handleTouchStart = e => {
    const { clientX, clientY } = e.changedTouches[0];
    const mouseDownEvent = { clientX, clientY };
    handleMouseDown(mouseDownEvent);
  };

  return (
    <div className={styles['field']} ref={field}>
      <FieldToolbar childRef={toolbar} />
      {!isGameCompleted && <TouchScreenToolbar childRef={touchScreenToolbar} />}
      <div className={styles['field-content']} ref={content}>
        <div
          className={styles['canvas-wrapper']}
          style={{ width: cellsInRow, height: cellsInRow }}
        >
          <canvas
            id="canvas"
            className={styles['canvas']}
            height={800}
            width={800}
            ref={canvas}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
          >
            Your browser does not support canvas graphic
          </canvas>
        </div>
      </div>
    </div>
  );
};

Field.propTypes = {
  isGameCompleted: PropTypes.bool,
  matrix: PropTypes.arrayOf(PropTypes.number),
  selectedValue: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  squares: PropTypes.arrayOf(PropTypes.object),
  moveSquares: PropTypes.func.isRequired,
};

Field.defaultProps = {
  isGameCompleted: false,
  matrix: [],
  selectedValue: 0,
  squares: [],
};

export default Field;
