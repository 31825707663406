const correctCoordinate = (coordinate, checkEdge, cellsInRow) => {
  let result = coordinate;
  if (result < 0) {
    result = checkEdge ? 0 : cellsInRow - 1;
  }
  if (result > cellsInRow - 1) {
    result = checkEdge ? cellsInRow - 1 : 0;
  }

  return result;
};

export default correctCoordinate;
