const DEFAULT_CELLS_IN_ROW = 4;

const CANVAS_SIZE = 800;

const FIELD_CONFIG = {
  DEFAULT_CELLS_IN_ROW,
  CANVAS_SIZE,
};

export default FIELD_CONFIG;
