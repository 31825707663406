import { connect } from 'react-redux';

import GameIsOver from './GameIsOver';
import { getIsCurrentGameCompleted } from '../../../store/selectors';
import { acAddModal, acPing } from '../../../core/store/actions';

const mapStateToProps = state => ({
  gameIsCompleted: getIsCurrentGameCompleted(state),
});

const mapDispatchToProps = {
  openModal: acAddModal,
  ping: acPing,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameIsOver);
