import React from 'react';

import Modal from '../../../core/components/Modal';
import GalleryForm from './shared/GalleryForm';
import { MODALS_ID } from '../../../constants';

import styles from './Gallery.module.scss';

const Gallery = () => {
  return (
    <Modal
      id={MODALS_ID.GALLERY}
      className={styles['gallery']}
      closeOnClick
      closeOnEscape
      isCentered
    >
      <GalleryForm />
    </Modal>
  );
};

export default Gallery;
