import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Form from '../../../../Form';
import FormFieldset from '../../../../Form/shared/FormFieldset';
import Legend from '../../../../Form/shared/Legend';
import Menu from '../../../../Menu';

import OPTIONS from './options';

import styles from './MainMenuForm.module.scss';

const MainMenuForm = ({
  isLogged,
  closeAllModals,
  closeModal,
  openModal,
  ...gameMainMenuProps
}) => {
  const history = useHistory();
  const [parentId, setParentId] = useState();

  const handleLinkClick = useCallback(linkUrl => e => {
    e.preventDefault();
    history.push(linkUrl);
    closeAllModals();
  }, [closeAllModals, history]);

  const goTo = optionId => () => setParentId(optionId);

  const options = useMemo(
    () =>
      OPTIONS({
        isLogged,
        parentId,
        closeAllModals,
        handleLinkClick,
        goTo,
        openModal,
        ...gameMainMenuProps,
      }),
    [closeAllModals, gameMainMenuProps, handleLinkClick, isLogged, openModal, parentId],
  );

  return (
    <Form
      className={styles['main-menu-form']}
      name="mainMenu"
      withCross
      onCrossClick={closeModal}
    >
      <FormFieldset>
        <Legend>Main menu</Legend>
        <Menu options={options} />
      </FormFieldset>
    </Form>
  );
};

MainMenuForm.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  closeAllModals: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default MainMenuForm;
