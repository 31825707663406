import { FIELD } from '../../constants';
import Square from '../../classes/Square/Square';

const { CANVAS_SIZE } = FIELD;

/**
 * Layout - array of numbers
 * @typedef {number[]} Layout
 */

/**
 * Converts Layout to array of squares or creates array of squares in order
 * @param{Layout} layout
 * @returns {Square[]} Array of squares
 */
const createSquares = layout => {
  const cellsInRow = Math.sqrt(layout.length);
  const size = CANVAS_SIZE / cellsInRow;

  return layout
    .map((value, i) => {
      if (value === 0) {
        return null;
      }
      const y = Math.floor(i / cellsInRow);
      const x = i - cellsInRow * y;

      return new Square({
        cx: x * size + size / 2,
        cy: y * size + size / 2,
        fieldX: x,
        fieldY: y,
        size,
        value,
      });
    })
    .filter(Boolean);
};

export default createSquares;
