const MODELS = {
  MODEL_ACTIONS: 'game.model.actions',
  MODEL_CELLS_IN_ROW: 'game.model.cellsInRow',
  MODEL_SELECTED_VALUE: 'game.model.selectedValue',
  MODEL_SQUARES: 'game.model.squares',
  NETWORKING: 'game.networking',
  NETWORKING_GAMES_DATA: 'game.networking.gamesData',
};

export default MODELS;
