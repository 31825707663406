const filterSquaresByProps = (squares, filter) => {
  const filterKeys = Object.keys(filter);
  return squares.filter(item => {
    let test = true;
    for (let i = 0; i < filterKeys.length; i++) {
      const key = filterKeys[i];
      test = test && key in item.props && item.props[key] === filter[key];
      if (!test) {
        break;
      }
    }
    return test;
  });
};

export default filterSquaresByProps;
