import createSquares from './create-squares';
import { findItem } from '../../core/utils/common-utils';
import getMovedSquares from './get-moved-squares';

const quickLoadedToState = ({ start, movements }) => {
  const { matrix, selectedValue } = start;
  const actions = [{ ...start }];

  let state = {
    actions,
    canceledActions: [],
    cellsInRow: Math.sqrt(matrix.length),
    isSaved: false,
    selectedValue,
    squares: createSquares(matrix),
  };

  movements.forEach(value => {
    const selectedSquare = findItem(
      state.squares,
      { value },
      (key, item, filter) => item.props[key] === filter[key],
    );

    state = getMovedSquares(state, selectedSquare);
  });

  return state;
};

export default quickLoadedToState;
