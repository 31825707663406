import roundedRect from '../../core/utils/canvas/rounded-rect';
import { SQUARE } from '../../constants';

const { MAX_SPEED } = SQUARE;

class Square {
  /**
   * @param {object} props
   * @param {number} props.fieldX Coordinate X in field`s model
   * @param {number} props.fieldY Coordinate Y in field`s model
   * @param {number} props.size
   * @param {number} props.cx Coordinate X of center of square
   * @param {number} props.cy Coordinate Y of center of square
   * @param {number} props.value
   * @param {boolean} props.isSelected
   * @param {boolean} props.isProperPosition (is set up by component)
   * @param {boolean} props.isMoving (is set up by component)
   * */
  constructor(props) {
    this.props = props;
    this.draw = this.draw.bind(this);
    this.getIsProperPosition = this.getIsProperPosition.bind(this);
    this.render = this.render.bind(this);
    this.setProps = this.setProps.bind(this);
    this.update = this.update.bind(this);
  }

  setProps(patch = {}) {
    this.props = { ...this.props, ...patch };

    return this;
  }

  getIsProperPosition(matrix) {
    return !!matrix && matrix[this.props.value - 1] === this.props.value;
  }

  update(cx, cy) {
    let deltaX = cx - this.props.cx;
    let deltaY = cy - this.props.cy;
    deltaX = deltaX !== 0 ? (deltaX / Math.abs(deltaX)) * MAX_SPEED : 0;
    deltaY = deltaY !== 0 ? (deltaY / Math.abs(deltaY)) * MAX_SPEED : 0;
    const newCx = this.props.cx + deltaX;
    const newCy = this.props.cy + deltaY;

    let newDeltaX = cx - newCx;
    let newDeltaY = cy - newCy;
    newDeltaX =
      newDeltaX !== 0 ? (newDeltaX / Math.abs(newDeltaX)) * MAX_SPEED : 0;
    newDeltaY =
      newDeltaY !== 0 ? (newDeltaY / Math.abs(newDeltaY)) * MAX_SPEED : 0;

    this.props.cx = newDeltaX / deltaX > 0 ? newCx : cx;
    this.props.cy = newDeltaY / deltaY > 0 ? newCy : cy;
  }

  draw(context) {
    // gradient
    const gradient = context.createRadialGradient(
      this.props.cx,
      this.props.cy,
      this.props.size * 0.425,
      this.props.cx,
      this.props.cy,
      this.props.size * 0.375,
    );

    const fill0 = 'rgba(255, 255, 255, 0.8)';

    const fill1 = 'rgba(255, 255, 255, 1)';

    gradient.addColorStop(0, fill0);
    gradient.addColorStop(1, fill1);

    context.fillStyle = this.props.isSelected
      ? gradient // 'rgba(255, 255, 255, 0.8)'
      : 'rgba(255, 255, 255, 1)';
    context.strokeStyle = 'rgba(0, 0, 0, 0.5)';
    roundedRect(
      context,
      this.props.cx,
      this.props.cy,
      this.props.size,
      this.props.size,
      8,
      true,
    );
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.font = `bold ${this.props.size / 2}px sans-serif`;
    context.fillStyle = 'rgba(0, 0, 0, 0.5)';
    context.fillText(`${this.props.value}`, this.props.cx, this.props.cy + 2);
    if (this.props.isProperPosition) {
      context.fillStyle = 'gold';
      context.fillText(`${this.props.value}`, this.props.cx - 2, this.props.cy);
    }
  }

  render({ context, matrix }) {
    this.props.isProperPosition = this.getIsProperPosition(matrix);

    // calculation of final coords
    const cx = this.props.fieldX * this.props.size + this.props.size / 2;
    const cy = this.props.fieldY * this.props.size + this.props.size / 2;

    // calculation of next coords in canvas
    this.props.isMoving = cx !== this.props.cx || cy !== this.props.cy;

    if (this.props.isMoving) {
      this.update(cx, cy);
    }

    this.draw(context);

    return this;
  }
}

export default Square;
