import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Toolbar.module.scss';

const Toolbar = ({ bestResult, count, isCompleted, isLogged, childRef }) => {
  const countClassName = classNames({
    [styles['toolbar-item']]: true,
    [styles['toolbar-item--great']]: isCompleted && bestResult > count,
    [styles['toolbar-item--warn']]: bestResult > 0 && count > bestResult,
  });

  return (
    <div className={styles['toolbar']} ref={childRef}>
      {isLogged && bestResult > 0 && (
        <span className={styles['toolbar-item']}>Best result: {bestResult}</span>
      )}
      <span className={countClassName}>Movements: {count}</span>
    </div>
  );
};

Toolbar.propTypes = {
  bestResult: PropTypes.number,
  count: PropTypes.number,
  isCompleted: PropTypes.bool,
  isLogged: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  childRef: PropTypes.object.isRequired,
};

Toolbar.defaultProps = {
  bestResult: 0,
  count: 0,
  isCompleted: false,
  isLogged: false,
};

export default Toolbar;
