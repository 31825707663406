import {
  AC_CHANGE_CELLS_IN_ROW,
  AC_SET_QUICK_LOADED_TO_STATE,
  AC_MOVE_BY_SELECTION,
  AC_SET_LAYOUT,
  AC_RESET_CURRENT_LAYOUT,
  AC_SET_SQUARES,
  AC_SELECTED_TO_LEFT,
  AC_SELECTED_TO_RIGHT,
  AC_SELECTED_TO_UP,
  AC_SELECTED_TO_DOWN,
  AC_SQUARES_TO_LEFT,
  AC_SQUARES_TO_RIGHT,
  AC_SQUARES_TO_UP,
  AC_SQUARES_TO_DOWN,
  AC_UNDO_ACTION,
  AC_REDO_ACTION,
} from '../actions/action-types';
import { FIELD } from '../../constants';
import setLayout from '../../utils/store/set-layout';
import moveSelectedItem from '../../utils/store/move-selected-item';
import moveBySelection from '../../utils/store/move-by-selection';
import moveSquaresValue from '../../utils/store/move-squares-value';
import undoAction from '../../utils/store/undo-action';
import redoAction from '../../utils/store/redo-action';
import quickLoadedToState from '../../utils/store/quick-loaded-to-state';

const initialState = {
  actions: [],
  canceledActions: [],
  cellsInRow: FIELD.DEFAULT_CELLS_IN_ROW,
  selectedValue: 0,
  squares: [],
};

const modelReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case AC_CHANGE_CELLS_IN_ROW:
      return {
        ...state,
        cellsInRow: payload.cellsInRow,
      };

    case AC_SET_QUICK_LOADED_TO_STATE:
      return quickLoadedToState(payload);

    case AC_MOVE_BY_SELECTION:
      return moveBySelection(state, payload);

    case AC_SET_LAYOUT:
      return setLayout(state, payload.layout);

    case AC_RESET_CURRENT_LAYOUT:
      return setLayout(state, state.actions[0].matrix);

    case AC_SET_SQUARES:
      return {
        ...state,
        squares: payload.squares,
      };

    case AC_SELECTED_TO_LEFT:
      return moveSelectedItem(state, -1, 0);

    case AC_SELECTED_TO_RIGHT:
      return moveSelectedItem(state, 1, 0);

    case AC_SELECTED_TO_UP:
      return moveSelectedItem(state, 0, -1);

    case AC_SELECTED_TO_DOWN:
      return moveSelectedItem(state, 0, 1);

    case AC_SQUARES_TO_LEFT:
      return moveSquaresValue(state, -1, 0);

    case AC_SQUARES_TO_RIGHT:
      return moveSquaresValue(state, 1, 0);

    case AC_SQUARES_TO_UP:
      return moveSquaresValue(state, 0, -1);

    case AC_SQUARES_TO_DOWN:
      return moveSquaresValue(state, 0, 1);

    case AC_UNDO_ACTION:
      return undoAction(state);

    case AC_REDO_ACTION:
      return redoAction(state);

    default:
      return state;
  }
};

export default modelReducer;
