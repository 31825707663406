import { MODALS_ID } from '../../../constants';

const getMainMenuOptions = ({
  isLogged,
  parentId,
  closeAllModals,
  goTo,
  openModal,
  ...rest
}) => {
  const {
    cellsInRow,
    isCurrentGameCompleted,
    isGameStarted,
    changeCellsInRow,
    randomLayout,
    replay,
    quickSave,
    quickLoad,
    loadGamesByCurrentLayout,
    saveCurrentGame,
  } = rest;

  const changeFieldSize = size => () => {
    changeCellsInRow(size);
    closeAllModals();
  };

  const setRandomLayout = () => {
    randomLayout();
    closeAllModals();
  };

  return [
    {
      id: 'replay',
      label: 'Replay current layout',
      isVisible: isGameStarted,
      onClick: () => {
        replay();
        closeAllModals();
      },
    },
    {
      id: 'randomLayout',
      label: 'Random layout',
      onClick: setRandomLayout,
    },
    {
      id: 'layoutFromGallery',
      label: 'Layout from gallery',
      isVisible: isLogged,
      onClick: () => {
        openModal(MODALS_ID.GALLERY);
      },
    },
    {
      id: 'quickSave',
      label: 'Quick save',
      isVisible: isLogged && !isCurrentGameCompleted,
      onClick: async () => {
        await quickSave();
        closeAllModals();
      },
    },
    {
      id: 'quickLoad',
      label: 'Quick load',
      isVisible: isLogged,
      onClick: async () => {
        await quickLoad();
        closeAllModals();
      },
    },
    {
      id: 'saveToGallery',
      label: 'Save to gallery',
      isVisible: isLogged && isCurrentGameCompleted,
      onClick: async () => {
        await saveCurrentGame();
        await loadGamesByCurrentLayout();
        closeAllModals();
      },
    },
    {
      id: 'changeFieldSize',
      label: 'Change field size',
      onClick: goTo('changeFieldSize'),
      children: [
        {
          id: 'back',
          label: '..',
          onClick: goTo(),
        },
        {
          id: `size3`,
          label: `3 x 3 (8 squares)`,
          isVisible: cellsInRow !== 3,
          onClick: changeFieldSize(3),
        },
        {
          id: `size4`,
          label: `4 x 4 (15 squares)`,
          isVisible: cellsInRow !== 4,
          onClick: changeFieldSize(4),
        },
        {
          id: `size5`,
          label: `5 x 5 (24 squares)`,
          isVisible: cellsInRow !== 5,
          onClick: changeFieldSize(5),
        },
        {
          id: `size6`,
          label: `6 x 6 (35 squares)`,
          isVisible: cellsInRow !== 6,
          onClick: changeFieldSize(6),
        },
        {
          id: `size7`,
          label: `7 x 7 (48 squares)`,
          isVisible: cellsInRow !== 7,
          onClick: changeFieldSize(7),
        },
        {
          id: `size8`,
          label: `8 x 8 (63 squares)`,
          isVisible: cellsInRow !== 8,
          onClick: changeFieldSize(8),
        },
      ],
    },
    {
      id: 'help',
      label: 'Help',
      onClick: goTo('help'),
      children: [
        {
          id: 'back',
          label: '..',
          onClick: goTo(),
        },
        {
          id: 'controls',
          label: 'Controls',
          onClick: goTo('controls'),
          children: [
            {
              id: 'back',
              label: '..',
              onClick: goTo('help'),
            },
            {
              id: 'mouse',
              label: 'Mouse, touchscreen',
              onClick: goTo('mouse'),
              children: [
                {
                  id: 'back',
                  label: '..',
                  onClick: goTo('controls'),
                },
                {
                  id: 'movementsByMouse',
                  label:
                    'Just click on tile to move one or group of tiles. The same - for touchscreen. You need to touch necessary tile.',
                },
              ],
            },
            {
              id: 'keyboard',
              label: 'Keyboard',
              onClick: goTo('keyboard'),
              children: [
                {
                  id: 'back',
                  label: '..',
                  onClick: goTo('controls'),
                },
                {
                  id: 'selectionByKeyboard',
                  label: 'Just use arrow keys to select necessary tile.',
                },
                {
                  id: 'movementsByKeyboard',
                  label:
                    'Hold on Control or Meta (on Mac) button and necessary arrow to move one tiles or a group.',
                },
              ],
            },
          ],
        },
        {
          id: 'network',
          label: 'Save/load game',
          onClick: goTo('network'),
          children: [
            {
              id: 'back',
              label: '..',
              onClick: goTo('help'),
            },
            {
              id: 'warning',
              label: 'You must be logged in!',
            },
            {
              id: 'quick',
              label:
                '`Quick save` lets you save current game. It is possible to save one game for each size of field. Use `Quick load` if you want to continue a game which was saved previously by `Quick save`.',
            },
            {
              id: 'saveToGallery',
              label:
                'When the game is completed you can save the results to the Games Gallery. Just use `Save To Gallery` for that.',
            },
          ],
        },
      ],
    },
  ];
};

export default getMainMenuOptions;
