import React from 'react';
import PropTypes from 'prop-types';

import coreConfig from '../../../../../.corerc';

import styles from './Content.module.scss';

const { contents } = coreConfig;

const Content = ({ pageId }) => {
  const defaultPageContent = () => 'Default page content';
  const PageContent = contents[pageId] || defaultPageContent;

  return (
    <section className={styles['content']}>
      <PageContent />
    </section>
  );
};

Content.propTypes = {
  pageId: PropTypes.string.isRequired,
};

Content.defaultProps = {};

export default Content;
