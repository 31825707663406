/**
 * Validates given layout whether it solvable or not
 * @param {number[]} layout
 * @returns {boolean} Validation result
 */
const validateLayout = layout => {
  const cellsInRow = Math.sqrt(layout.length);
  const k = Math.floor(layout.indexOf(0) / cellsInRow) + 1;

  const n = layout
    .filter(item => item > 0)
    .reduce((acc, value, i, arr) => {
      const inc = arr.slice(i + 1, arr.length).filter(item => item < arr[i])
        .length;

      return acc + inc;
    }, 0);

  return cellsInRow % 2 === 0 ? (n + k) % 2 === 0 : n % 2 === 0;
};

export default validateLayout;
