import getEntityPrefix from './get-entity-prefix';
import { ENTITY_STATUS_TYPE } from '../../core/constants';

const { SAVE, LOAD } = ENTITY_STATUS_TYPE;

const getStatusKey = statusType => entityId =>
  `${getEntityPrefix(entityId)}${statusType}Status`;

const getSaveStatusKey = getStatusKey(SAVE);

const getLoadStatusKey = getStatusKey(LOAD);

export { getSaveStatusKey, getLoadStatusKey };
