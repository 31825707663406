import { connect } from 'react-redux';

import Toolbar from './Toolbar';
import { getIsLogged } from '../../../../../../core/store/selectors';
import {
  getBestCount,
  getCount,
  getIsCurrentGameCompleted,
} from '../../../../../../store/selectors';

const mapStateToProps = state => ({
  bestResult: getBestCount(state),
  count: getCount(state),
  isCompleted: getIsCurrentGameCompleted(state),
  isLogged: getIsLogged(state),
});

export default connect(mapStateToProps)(Toolbar);
