import { isFilledArray } from '../../core/utils/common-utils';

const getFieldCoordinates = (clientX, clientY, element, matrix) => {
  if (!isFilledArray(matrix) || !element) {
    return {};
  }
  const cellsInRow = Math.sqrt(matrix.length);
  const rect = element.getBoundingClientRect();
  const x = clientX - rect.left;
  const y = clientY - rect.top;
  const size = rect.width / cellsInRow;
  const fieldX = Math.floor(x / size);
  const fieldY = Math.floor(y / size);

  return { x: fieldX, y: fieldY };
};

export default getFieldCoordinates;
