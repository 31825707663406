import { connect } from 'react-redux';

import FieldService from './FieldService';
import { getCellsInRow, getCurrentLayout } from '../../../store/selectors';
import { acSetLayout, loadGamesByCurrentLayout } from '../../../store/actions';
import { getIsLogged } from '../../../core/store/selectors';

const mapStateToProps = state => ({
  cellsInRow: getCellsInRow(state),
  isLogged: getIsLogged(state),
  layout: getCurrentLayout(state),
});

const mapDispatchToProps = {
  createLayout: acSetLayout,
  loadGames: loadGamesByCurrentLayout,
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldService);
