import getValidLayout from './get-valid-layout';
import createSquares from './create-squares';

const setLayout = (state, layout) => {
  const newLayout = layout || getValidLayout(state.cellsInRow);

  return {
    ...state,
    actions: [{ selectedValue: 1, matrix: newLayout }],
    canceledActions: [],
    isSaved: false,
    // layoutType: getTypeOfLayout(newLayout),
    // gameMode: GAME_MODES.STARTED,
    selectedValue: 1,
    squares: createSquares(newLayout),
  };
};

export default setLayout;
