import moveSquaresValue from './move-squares-value';

const getMovedSquares = (state, selectedSquare) => {
  let newState = {
    ...state,
    selectedValue: selectedSquare.props.value,
  };

  const options = [
    { deltaX: -1, deltaY: 0 }, // left
    { deltaX: 1, deltaY: 0 }, // right
    { deltaX: 0, deltaY: -1 }, // up
    { deltaX: 0, deltaY: 1 }, // down
  ];

  for (let i = 0; i < options.length; i++) {
    const { deltaX } = options[i];
    const { deltaY } = options[i];
    const actionsLength = newState.actions.length;
    newState = moveSquaresValue(newState, deltaX, deltaY);
    if (newState.actions.length > actionsLength) {
      break;
    }
  }

  return newState;
};

export default getMovedSquares;
