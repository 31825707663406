import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Page from '../Page';
import MainMenu from '../modals/MainMenu';
import AccountModalMenu from '../modals/AccountModalMenu';
import AuthModal from '../modals/AuthModal';
import ChangePasswordModal from '../modals/ChangePasswordModal';
import Preloader from '../modals/Preloader';
import coreConfig from '../../../.corerc';

const { routes: ROUTES } = coreConfig;

const Router = () => {
  const routes = ROUTES.map(({ url, ...rest }) => (
    <Route key={rest.id} exact path={url}>
      <Page {...rest} />
    </Route>
  ));

  return (
    <BrowserRouter>
      <Switch>
        {routes}

        <Route>
          <div>Page 404. Resource was not found.</div>
        </Route>
      </Switch>
      <MainMenu />
      <AccountModalMenu />
      <AuthModal />
      <ChangePasswordModal />
      <Preloader />
    </BrowserRouter>
  );
};

export default Router;
