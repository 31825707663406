import { connect } from 'react-redux';

import MainMenuForm from './MainMenuForm';
import {
  acCloseAllModals,
  acAddModal,
  acRemoveLastModal,
} from '../../../../../store/actions';
import { getIsLogged } from '../../../../../store/selectors';
import coreConfig from '../../../../../../.corerc';

const { mainMenuActions = {}, getMainMenuProps = () => ({}) } = coreConfig;

const mapStateToProps = state => {
  const mainMenuProps = getMainMenuProps(state);

  return {
    isLogged: getIsLogged(state),
    ...mainMenuProps,
  };
};

const mapDispatchToProps = {
  closeAllModals: acCloseAllModals,
  closeModal: acRemoveLastModal,
  openModal: acAddModal,
  ...mainMenuActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuForm);
