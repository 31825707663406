import { connect } from 'react-redux';

import TouchScreenToolbar from './TouchScreenToolbar';
import {
  acRedoAction,
  acUndoAction,
} from '../../../../../../store/actions/creators';

const mapDispatchToProps = {
  redo: acRedoAction,
  undo: acUndoAction,
};

export default connect(null, mapDispatchToProps)(TouchScreenToolbar);
