import {
  AC_CHANGE_CELLS_IN_ROW,
  AC_MOVE_BY_SELECTION,
  AC_SET_QUICK_LOADED_TO_STATE,
  AC_SET_LAYOUT,
  AC_RESET_CURRENT_LAYOUT,
  AC_SET_SQUARES,
  AC_SELECTED_TO_LEFT,
  AC_SELECTED_TO_RIGHT,
  AC_SELECTED_TO_UP,
  AC_SELECTED_TO_DOWN,
  AC_SQUARES_TO_LEFT,
  AC_SQUARES_TO_RIGHT,
  AC_SQUARES_TO_UP,
  AC_SQUARES_TO_DOWN,
  AC_UNDO_ACTION,
  AC_REDO_ACTION,
} from '../action-types';

export const acChangeCellsInRow = cellsInRow => ({
  type: AC_CHANGE_CELLS_IN_ROW,
  payload: { cellsInRow },
});

export const acSetQuickLoadedToState = (start, movements) => ({
  type: AC_SET_QUICK_LOADED_TO_STATE,
  payload: { start, movements },
});

export const acMoveBySelection = (fieldX, fieldY) => ({
  type: AC_MOVE_BY_SELECTION,
  payload: { fieldX, fieldY },
});

export const acSelectedToLeft = () => ({
  type: AC_SELECTED_TO_LEFT,
});

export const acSelectedToRight = () => ({
  type: AC_SELECTED_TO_RIGHT,
});

export const acSelectedToUp = () => ({
  type: AC_SELECTED_TO_UP,
});

export const acSelectedToDown = () => ({
  type: AC_SELECTED_TO_DOWN,
});

export const acSquaresToLeft = () => ({
  type: AC_SQUARES_TO_LEFT,
});

export const acSquaresToRight = () => ({
  type: AC_SQUARES_TO_RIGHT,
});

export const acSquaresToUp = () => ({
  type: AC_SQUARES_TO_UP,
});

export const acSquaresToDown = () => ({
  type: AC_SQUARES_TO_DOWN,
});

export const acUndoAction = () => ({
  type: AC_UNDO_ACTION,
});

export const acRedoAction = () => ({
  type: AC_REDO_ACTION,
});

export const acSetLayout = layout => ({
  type: AC_SET_LAYOUT,
  payload: { layout },
});

export const acResetCurrentLayout = () => ({
  type: AC_RESET_CURRENT_LAYOUT,
});

export const acSetSquares = squares => ({
  type: AC_SET_SQUARES,
  payload: { squares },
});
