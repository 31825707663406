import React from 'react';
import { Provider } from 'react-redux';

import MatchMediaService from '../MatchMediaService';
import Router from '../Router';
import Controller from '../Controller';
import AuthInfoService from '../AuthInfoService';
import SnackService from '../SnackService';
import configureStore from '../../store';
import { MEDIA_BREAKPOINTS } from '../../constants';
import eventEmitter from './shared/event-emitter';

const { MOBILE, DESKTOP, TABLET } = MEDIA_BREAKPOINTS;

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <>
        <MatchMediaService mediaQueries={[MOBILE, TABLET, DESKTOP]} />
        <Controller eventEmitter={eventEmitter} />
        <AuthInfoService />
        <Router />
        <SnackService />
      </>
    </Provider>
  );
}

export default App;
