import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import Form from '../../../../../core/components/Form';
import FormFieldset from '../../../../../core/components/Form/shared/FormFieldset';
import Legend from '../../../../../core/components/Form/shared/Legend';
import List from '../../../../../core/components/List';
import GalleryItem from '../GalleryItem';
import { isFilledArray } from '../../../../../core/utils/common-utils';
import { getLayoutFromHash } from '../../../../../utils/store/layout-hash-transformation';

import styles from './GalleryForm.module.scss';

const GalleryForm = ({
  galleryData,
  closeAllModals,
  closeModal,
  loadGallery,
  setLayout,
}) => {
  const list = useRef(null);

  useEffect(() => {
    loadGallery();
  }, [loadGallery]);

  const handleItemClick = useCallback(hash => () => {
    const layout = getLayoutFromHash(hash);
    setLayout(layout);
    closeAllModals();
  }, [closeAllModals, setLayout]);

  const galleryItems = useMemo(() => {
    if (isFilledArray(galleryData)) {
      return galleryData.map(({ hash, items }) => (
        <GalleryItem
          key={hash}
          hash={hash}
          items={items}
          onClick={handleItemClick(hash)}
        />
      ));
    }

    return <li>No data is available</li>;
  }, [galleryData, handleItemClick]);

  return (
    <Form
      className={styles['gallery-form']}
      name="gallery"
      withCross
      onCrossClick={closeModal}
    >
      <FormFieldset className={styles['gallery-form-fieldset']}>
        <Legend className={styles['gallery-form-fieldset__title']}>
          Layouts` Gallery
        </Legend>
        <section className={styles['gallery-form-fieldset__content']}>
          <List className={styles['gallery-list']} childRef={list}>
            {galleryItems}
          </List>
        </section>
      </FormFieldset>
    </Form>
  );
};

GalleryForm.propTypes = {
  galleryData: PropTypes.arrayOf(PropTypes.shape({})),
  closeAllModals: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  loadGallery: PropTypes.func.isRequired,
  setLayout: PropTypes.func.isRequired,
};

GalleryForm.defaultProps = {
  galleryData: null,
};

export default GalleryForm;
