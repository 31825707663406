import { useEffect } from 'react';
import PropTypes from 'prop-types';

const NetworkService = ({ gamesShouldBeLoaded, loadGames }) => {
  useEffect(() => {
    if (gamesShouldBeLoaded) {
      loadGames();
    }
  }, [gamesShouldBeLoaded, loadGames]);

  return null;
};

NetworkService.propTypes = {
  gamesShouldBeLoaded: PropTypes.bool.isRequired,
  loadGames: PropTypes.func.isRequired,
};

export default NetworkService;
