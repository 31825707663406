import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../core/components/Modal';
import GameIsOverForm from './shared/GameIsOverForm';

import styles from './GameIsOver.module.scss';
import { MODALS_ID } from '../../../constants';

const { GAME_IS_OVER } = MODALS_ID;

const GameIsOver = ({ gameIsCompleted, openModal, ping }) => {
  useEffect(() => {
    if (gameIsCompleted) {
      ping().then(() => {
        openModal(GAME_IS_OVER);
      });
    }
  }, [gameIsCompleted, openModal, ping]);

  return (
    <Modal
      id={GAME_IS_OVER}
      className={styles['game-is-over']}
      isCentered
      closeOnClick
      closeOnEscape
    >
      <GameIsOverForm />
    </Modal>
  );
};

GameIsOver.propTypes = {
  gameIsCompleted: PropTypes.bool,
  openModal: PropTypes.func,
  ping: PropTypes.func,
};

GameIsOver.defaultProps = {
  gameIsCompleted: false,
  openModal: () => {},
  ping: () => {},
};

export default GameIsOver;
