import React from 'react';

import Field from './shared/Field';
import FieldService from '../services/FieldService';
import FieldController from '../Controller';
import NetworkService from '../services/NetworkService';
import Gallery from '../modals/Gallery';
import GameIsOver from '../modals/GameIsOver';

const MainContent = () => {
  return (
    <>
      <Field />
      <FieldService />
      <FieldController />
      <NetworkService />
      <Gallery />
      <GameIsOver />
    </>
  );
};

export default MainContent;
