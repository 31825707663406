import { connect } from 'react-redux';

import NetworkService from './NetworkService';
import { getGamesShouldBeLoaded } from '../../../store/selectors';
import { loadGames } from '../../../store/actions';

const mapStateToProps = state => ({
  gamesShouldBeLoaded: getGamesShouldBeLoaded(state),
});

const mapDispatchToProps = {
  loadGames,
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkService);
