import { connect } from 'react-redux';

import GameIsOverForm from './GameIsOverForm';
import { getCount, getBestCount } from '../../../../../store/selectors';

const mapStateToProps = state => ({
  count: getCount(state),
  bestCount: getBestCount(state),
});

export default connect(mapStateToProps)(GameIsOverForm);
