import { ROUTES } from './constants';
import api from './api';
import reducer from './store/reducers';
import {
  acChangeCellsInRow,
  acSetLayout,
  acResetCurrentLayout,
  quickSaveCurrentGame,
  quickLoadCurrentGame,
  loadGamesByCurrentLayout,
  saveCurrentGame,
} from './store/actions';
import {
  getCellsInRow,
  getIsCurrentGameCompleted,
  getCount,
  loadStatusSelectorsList,
  saveStatusSelectorsList
} from './store/selectors';
import getMainMenuOptions from './components/core-shared/main-menu-options';
import FIFTEEN_PUZZLE from './components/MainContent';

const getMainMenuProps = state => ({
  cellsInRow: getCellsInRow(state),
  isGameStarted: getCount(state) > 0,
  isCurrentGameCompleted: getIsCurrentGameCompleted(state),
});

const mainMenuActions = {
  changeCellsInRow: acChangeCellsInRow,
  randomLayout: acSetLayout,
  replay: acResetCurrentLayout,
  quickSave: quickSaveCurrentGame,
  quickLoad: quickLoadCurrentGame,
  loadGamesByCurrentLayout,
  saveCurrentGame,
};

export default {
  api,
  reducer,
  routes: ROUTES,
  loadStatusSelectorsList,
  saveStatusSelectorsList,
  getMainMenuProps,
  mainMenuActions,
  getMainMenuOptions,
  contents: { FIFTEEN_PUZZLE },
};
