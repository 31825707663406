import {
  AC_ENTITY_SHOULD_BE_LOADED,
  AC_ENTITY_LOADING_START,
  AC_ENTITY_LOADING_ERROR,
  AC_ENTITY_LOADING_SUCCESS,
  AC_ENTITY_SAVE_START,
  AC_ENTITY_SAVE_ERROR,
  AC_ENTITY_SAVE_SUCCESS,
} from '../actions/action-types';
import { AC_RESET_APP } from '../../core/store/actions/action-types';
import { getSaveStatusKey } from '../../utils/store/get-status-key';

const initialState = {
  galleryLoadStatus: 2,
  galleryData: null,
  errors: [],
  gamesLoadStatus: 2,
  gamesData: null,
};

const networkingReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case AC_ENTITY_SHOULD_BE_LOADED: {
      const { entityId } = payload;
      const loadStatusKey = `${entityId}LoadStatus`;

      return {
        ...state,
        [loadStatusKey]: 0,
      };
    }

    case AC_ENTITY_LOADING_START: {
      const { entityId } = payload;
      const loadStatusKey = `${entityId}LoadStatus`;

      return {
        ...state,
        [loadStatusKey]: 1,
      };
    }

    case AC_ENTITY_LOADING_ERROR: {
      const { entityId, error } = payload;
      const loadStatusKey = `${entityId}LoadStatus`;
      const dataKey = `${entityId}Data`;
      const errors = [...state.errors, error];

      return {
        ...state,
        [loadStatusKey]: 3,
        [dataKey]: null,
        errors,
      };
    }

    case AC_ENTITY_LOADING_SUCCESS: {
      const { entityId, data } = payload;
      const loadStatusKey = `${entityId}LoadStatus`;
      const dataKey = `${entityId}Data`;

      return {
        ...state,
        [loadStatusKey]: 2,
        [dataKey]: data,
      };
    }

    case AC_ENTITY_SAVE_START: {
      const { entityId } = payload;
      const saveStatusKey = getSaveStatusKey(entityId);

      return {
        ...state,
        [saveStatusKey]: 1,
      };
    }

    case AC_ENTITY_SAVE_ERROR: {
      const { entityId, error } = payload;
      const saveStatusKey = getSaveStatusKey(entityId);
      const errors = [...state.errors, error];

      return {
        ...state,
        [saveStatusKey]: 3,
        errors,
      };
    }

    case AC_ENTITY_SAVE_SUCCESS: {
      const { entityId } = payload;
      const saveStatusKey = getSaveStatusKey(entityId);

      return {
        ...state,
        [saveStatusKey]: 2,
      };
    }

    case AC_RESET_APP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default networkingReducer;
