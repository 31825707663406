import doLastAction from './do-last-action';
import { getLast } from '../../core/utils/common-utils';
import { getIsCurrentGameCompleted } from '../../store/selectors';

const redoAction = state => {
  if (!getIsCurrentGameCompleted({ game: { model: state } })) {
    let { canceledActions } = state;
    const { actions, squares } = state;
    if (canceledActions.length > 0) {
      const renewed = getLast(canceledActions);
      canceledActions = canceledActions.slice(0, canceledActions.length - 1);
      actions.push(renewed);

      return {
        ...state,
        ...doLastAction(actions, squares),
        actions: actions.slice(),
        canceledActions,
      };
    }
  }

  return state;
};

export default redoAction;
