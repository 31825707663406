import { randomFromRange } from '../../core/utils/common-utils';

/**
 * Creates random layout
 * @param {number} cellsInRow
 * @returns {number[]} Random layout
 */
const createRandomLayout = cellsInRow => {
  const count = cellsInRow ** 2;
  const layout = Array(count).fill(0);
  for (let i = 0; i < count - 1; i++) {
    let value = 0;
    do {
      value = randomFromRange(1, count - 1);
    } while (layout.includes(value));

    let j = 0;
    do {
      j = randomFromRange(0, count - 1);
    } while (layout[j] > 0);
    layout[j] = value;
  }

  return layout;
  // return [4, 12, 9, 3, 10, 8, 13, 2, 1, 7, 0, 14, 6, 11, 5, 15];
};

export default createRandomLayout;
