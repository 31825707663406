import { getLast } from '../../core/utils/common-utils';

/**
 * Action
 * @typedef {Object} Action
 * @property {number} selectedValue
 * @property {number[]} matrix
 */

/**
 * Encodes actions
 * @param {Action[]} actions
 * @returns {Object} Encoded string
 */
const prepareToSave = actions => ({
  start: actions[0],
  movements: actions
    .slice(1, actions.length)
    .map(action => action.selectedValue),
  end: getLast(actions),
});

export default prepareToSave;
