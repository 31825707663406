import { findItem, findIndex } from '../../core/utils/common-utils';

const propsChecker = (key, item, filter) => {
  return key in item.props && item.props[key] === filter[key];
};

const findSquareIndexByProps = (arr, filter) =>
  findIndex(arr, filter, propsChecker);

const findSquareByProps = (arr, filter) => {
  return findItem(arr, filter, propsChecker);
};

export { findSquareIndexByProps, findSquareByProps };
