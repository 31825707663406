import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Form from '../../../../../core/components/Form';
import FormFieldset from '../../../../../core/components/Form/shared/FormFieldset';
import Legend from '../../../../../core/components/Form/shared/Legend';

import styles from './GameIsOverForm.module.scss';

const GameIsOverForm = ({ count, bestCount }) => {
  const isWinner = !!bestCount && bestCount >= count;
  const isLoser = !!bestCount && bestCount < count;

  const title = isWinner
    ? `Congratulations! You\xa0are\xa0the\xa0champion!`
    : 'Game is over!';

  const titleClassName = classNames(
    styles['title'],
    isWinner && styles['title--winner'],
  );

  // eslint-disable-next-line no-nested-ternary
  const countColor = isWinner
    ? 'rgba(255, 215, 0, 1)'
    : isLoser
    ? 'rgba(255, 0, 0, 1)'
    : 'rgba(255, 255, 255, 1)';

  return (
    <Form name="gameIsOver" className={styles['game-is-over-form']}>
      <FormFieldset>
        <Legend className={titleClassName}>{title}</Legend>
        <section>
          <p
            className={classNames(styles['record-line'], styles['score-info'])}
          >
            Count of movements:{' '}
            <span style={{ color: countColor }} className={styles['big-text']}>
              {count}
            </span>
          </p>
          {!!bestCount && (
            <p
              className={classNames(
                styles['record-line'],
                styles['score-info'],
              )}
            >
              Best result for this layout:
              <span className={styles['big-text']}>{bestCount}</span>
            </p>
          )}
        </section>
      </FormFieldset>
    </Form>
  );
};

GameIsOverForm.propTypes = {
  count: PropTypes.number,
  bestCount: PropTypes.number,
};

GameIsOverForm.defaultProps = {
  count: 0,
  bestCount: 0,
};

export default GameIsOverForm;
