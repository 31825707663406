import { findSquareByProps } from './find-square-by-props';
import getMovedSquares from './get-moved-squares';
import { getIsCurrentGameCompleted } from '../../store/selectors';

const moveBySelection = (state, { fieldX, fieldY }) => {
  if (!getIsCurrentGameCompleted({ game: { model: state } })) {
    const selectedSquare = findSquareByProps(state.squares, {
      fieldX,
      fieldY,
    });

    if (!selectedSquare) {
      return state;
    }

    return getMovedSquares(state, selectedSquare);
  }

  return state;
};

export default moveBySelection;
