import { createSelector } from 'reselect';
import { get } from 'lodash';

import { ENTITY_ID, ENTITIES, MODELS } from '../../constants';
import { getIsLogged } from '../../core/store/selectors';
import { getSaveStatusKey } from '../../utils/store/get-status-key';

const { GALLERY, GAMES } = ENTITY_ID;

const getEntityLoadStatus = entityId => state =>
  get(state, `${MODELS.NETWORKING}.${ENTITIES[entityId].prefix}LoadStatus`, 0);

const getEntitySaveStatus = entityId => state =>
  get(state, `${MODELS.NETWORKING}.${getSaveStatusKey(entityId)}`);

const getEntityData = entityId => state =>
  get(state, `${MODELS.NETWORKING}.${ENTITIES[entityId].prefix}Data`, null);

const getEntityShouldBeLoaded = entityId =>
  createSelector(
    [getEntityLoadStatus(entityId), getIsLogged],
    (loadStatus, isLogged) => {
      return isLogged && loadStatus === 0;
    },
  );

export const getGalleryShouldBeLoaded = getEntityShouldBeLoaded(GALLERY);
export const getGalleryData = getEntityData(GALLERY);

export const getGamesShouldBeLoaded = getEntityShouldBeLoaded(GAMES);

export const loadStatusSelectorsList = Object.values(ENTITY_ID).map(entityId =>
  getEntityLoadStatus(entityId),
);

export const saveStatusSelectorsList = Object.values(ENTITY_ID).map(entityId =>
  getEntitySaveStatus(entityId),
);
