import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const FieldService = ({
  cellsInRow,
  isLogged,
  layout,
  createLayout,
  loadGames,
}) => {
  const [previousCellsInRow, setPreviousCellsInRow] = useState(cellsInRow);

  const fieldSizeIsChanged = cellsInRow !== previousCellsInRow;

  useEffect(() => {
    setPreviousCellsInRow(cellsInRow);
    createLayout();
  }, [cellsInRow, createLayout, fieldSizeIsChanged]);

  useEffect(() => {
    if (layout && isLogged) {
      loadGames();
    }
  }, [isLogged, layout, loadGames]);

  return null;
};

FieldService.propTypes = {
  cellsInRow: PropTypes.number,
  isLogged: PropTypes.bool.isRequired,
  layout: PropTypes.arrayOf(PropTypes.number),
  createLayout: PropTypes.func,
  loadGames: PropTypes.func,
};

export default FieldService;
