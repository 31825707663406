import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getLast, isFilledArray } from '../../core/utils/common-utils';
import { MODELS } from '../../constants';

const {
  MODEL_ACTIONS,
  MODEL_CELLS_IN_ROW,
  MODEL_SELECTED_VALUE,
  MODEL_SQUARES,
  NETWORKING_GAMES_DATA,
} = MODELS;

export const getCellsInRow = state => get(state, MODEL_CELLS_IN_ROW);

export const getActions = state => get(state, MODEL_ACTIONS, []);

export const getMatrix = createSelector([getActions], actions =>
  isFilledArray(actions) ? getLast(actions).matrix : null,
);

export const getCurrentLayout = createSelector([getActions], actions =>
  isFilledArray(actions) ? actions[0].matrix : null,
);

export const getSquares = state => get(state, MODEL_SQUARES, []);

export const getSelectedValue = state => get(state, MODEL_SELECTED_VALUE);

export const getCount = createSelector([getActions], actions => {
  const { length } = actions;

  return length > 0 ? length - 1 : 0;
});

export const getIsCurrentGameCompleted = createSelector(
  [getActions],
  actions => {
    const { matrix } = getLast(actions, { matrix: [] });
    let isCompleted = false;
    for (let i = 1; i < matrix.length; i++) {
      isCompleted = matrix[i - 1] === i;
      if (!isCompleted) {
        break;
      }
    }

    return isCompleted;
  },
);

export const getGamesData = state => get(state, NETWORKING_GAMES_DATA);

export const getBestCount = createSelector([getGamesData], gamesData =>
  isFilledArray(gamesData) ? gamesData[0].count : 0,
);
