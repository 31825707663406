import {
  AC_REQUEST_START,
  AC_REQUEST_ERROR,
  AC_REQUEST_SUCCESS,
  AC_SET_ENTITY_DATA,
  AC_RESET_APP,
} from '../actions/action-types';

const initialState = {};

const networkingReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  const { entityId, error } = payload;

  switch (type) {
    case AC_REQUEST_START:
      return {
        ...state,
        [`${entityId}LoadStatus`]: 1,
      };

    case AC_REQUEST_ERROR:
      return {
        ...state,
        [`${entityId}LoadStatus`]: 3,
        [`${entityId}Error`]: error,
      };

    case AC_REQUEST_SUCCESS:
      return {
        ...state,
        [`${entityId}LoadStatus`]: 2,
        [`${entityId}Error`]: null,
      };

    case AC_SET_ENTITY_DATA:
      return {
        ...state,
        [`${entityId}Data`]: payload.data,
      };

    case AC_RESET_APP: {
      const errors = Object.keys(state).reduce(
        (acc, key) => ({
          ...acc,
          ...(key.includes('Error') && { [key]: state[key] }),
        }),
        {},
      );

      return {
        ...initialState,
        ...errors,
      };
    }

    default:
      return state;
  }
};

export default networkingReducer;
