import { findSquareByProps } from './find-square-by-props';
import { getLast } from '../../core/utils/common-utils';
import correctCoordinate from './correct-coordinate';

const getMovableValues = (selectedPlate, group, field, delta, cellsInRow) => {
  const movableGroup = [selectedPlate];
  let nextCoordinate = selectedPlate.props[field];
  let nextSquare = null;

  do {
    nextSquare = findSquareByProps(group, { [field]: nextCoordinate + delta });
    if (nextSquare) {
      movableGroup.push(nextSquare);
      nextCoordinate = nextSquare.props[field];
    }
  } while (nextSquare);

  const lastPlate = getLast(movableGroup);
  const newCoordinate = correctCoordinate(
    lastPlate.props[field] + delta,
    true,
    cellsInRow,
  );

  return newCoordinate !== lastPlate.props[field]
    ? movableGroup.map(square => {
        return square.props.value;
      })
    : [];
};

export default getMovableValues;
