import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ListItem from '../../../../../core/components/List/shared/ListItem';
import Square from '../../../../../classes/Square/Square';
import { getLayoutFromHash } from '../../../../../utils/store/layout-hash-transformation';
import { FIELD } from '../../../../../constants';
import roundedRect from '../../../../../core/utils/canvas/rounded-rect';

import styles from './GalleryItem.module.scss';

const { CANVAS_SIZE } = FIELD;

const GalleryItem = ({ hash, items, onClick }) => {
  const layout = useMemo(() => getLayoutFromHash(hash), [hash]);

  const canvas = useRef(null);

  useEffect(() => {
    const ctx = canvas.current.getContext('2d');
    const cellsInRow = Math.sqrt(layout.length);
    const bestItem = items[0];
    const { count, login } = bestItem;

    layout
      .map((value, i) => {
        const fieldY = Math.floor(i / cellsInRow);
        const fieldX = i - fieldY * cellsInRow;
        const size = CANVAS_SIZE / cellsInRow;
        const cy = fieldY * size + size / 2;
        const cx = fieldX * size + size / 2;
        return value > 0
          ? new Square({
              value,
              cx,
              cy,
              fieldX,
              fieldY,
              size,
              isSelected: false,
              isMoving: false,
              isProperPosition: i + 1 === value,
            })
          : null;
      })
      .filter(Boolean)
      .forEach(square => {
        square.draw(ctx);
      });

    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    const center = CANVAS_SIZE / 2;
    const width = CANVAS_SIZE * 0.8;
    const height = CANVAS_SIZE * 0.6;
    roundedRect(ctx, center, center, width, height, 16, true);
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = `bold ${height / 8}px sans-serif`;
    ctx.fillStyle = 'gold';
    ctx.fillText(`Best result: ${count}`, center, center - height / 6);
    ctx.fillText(`Winner: ${login}`, center, center + height / 6);
  }, [items, layout]);

  return (
    <ListItem className={styles['gallery-item']} onClick={onClick}>
      <canvas
        id={`item-${hash}`}
        className={styles['gallery-item__field']}
        width={CANVAS_SIZE}
        height={CANVAS_SIZE}
        ref={canvas}
      />
    </ListItem>
  );
};

GalleryItem.propTypes = {
  hash: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      login: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
};

GalleryItem.defaultProps = {
  onClick: () => {},
};

export default GalleryItem;
