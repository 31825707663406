import ENTITY_ID from './entity-id';

const { CURRENT_GAME, GALLERY, GAMES } = ENTITY_ID;

const ENTITIES = {
  [CURRENT_GAME]: {
    prefix: 'currentGame',
  },
  [GALLERY]: {
    prefix: 'gallery',
  },
  [GAMES]: {
    prefix: 'games',
  },
};

export default ENTITIES;
