const ROUTES = [
  {
    id: 'FIFTEEN_PUZZLE',
    url: '/',
    menuLabel: 'Fifteen puzzle',
    title: 'Fifteen Puzzle',
    pageTitle: 'Feature - Fifteen puzzle',
  },
];

export default ROUTES;
