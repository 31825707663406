import { connect } from 'react-redux';

import GalleryForm from './GalleryForm';
import { getGalleryData } from '../../../../../store/selectors';
import {
  acCloseAllModals,
  acRemoveLastModal,
} from '../../../../../core/store/actions';
import { loadGallery, acSetLayout } from '../../../../../store/actions';

const mapStateToProps = state => ({
  galleryData: getGalleryData(state),
});

const mapDispatchToProps = {
  closeAllModals: acCloseAllModals,
  closeModal: acRemoveLastModal,
  loadGallery,
  setLayout: acSetLayout,
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryForm);
