import { getLast } from '../../core/utils/common-utils';
import { findSquareIndexByProps } from './find-square-by-props';

/**
 * Sets props to squares based on last action matrix
 * Matrix items should have proper indexes to calculate x and y
 * @param {Object[]} actions
 * @param {Square[]} stateSquares
 * @returns {Object} Selected value and squares
 */
const doLastAction = (actions, stateSquares) => {
  const squares = stateSquares.slice();
  const action = getLast(actions);

  if (action) {
    const cellsInRow = Math.sqrt(action.matrix.length);
    action.matrix.forEach((value, i) => {
      if (value > 0) {
        const fieldY = Math.floor(i / cellsInRow);
        const fieldX = i - fieldY * cellsInRow;

        const squareIndex = findSquareIndexByProps(squares, { value });

        squares[squareIndex].setProps({
          value,
          fieldX,
          fieldY,
        });
      }
    });
  }

  // return action && action.selectedValue;
  return {
    selectedValue: action ? action.selectedValue : 1,
    squares,
  };
};

export default doLastAction;
