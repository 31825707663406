import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import eventEmitter from '../../core/components/App/shared/event-emitter';
import { EVENTS } from '../../core/constants';

const { KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_UP, REDO, UNDO } = EVENTS;

class Controller extends PureComponent {
  componentDidMount() {
    eventEmitter.on(KEY_LEFT, this.handleLeft);
    eventEmitter.on(KEY_RIGHT, this.handleRight);
    eventEmitter.on(KEY_UP, this.handleUp);
    eventEmitter.on(KEY_DOWN, this.handleDown);
    eventEmitter.on(REDO, this.handleRedo);
    eventEmitter.on(UNDO, this.handleUndo);
  }

  componentWillUnmount() {
    eventEmitter.off(KEY_LEFT, this.handleLeft);
    eventEmitter.off(KEY_RIGHT, this.handleRight);
    eventEmitter.off(KEY_UP, this.handleUp);
    eventEmitter.off(KEY_DOWN, this.handleDown);
    eventEmitter.off(REDO, this.handleRedo);
    eventEmitter.off(UNDO, this.handleUndo);
  }

  handleLeft = ({ ctrlKey }) => {
    const { isModalMode, acSquaresToLeft, acSelectedToLeft } = this.props;

    if (isModalMode) return;
    if (ctrlKey) {
      acSquaresToLeft();
    } else {
      acSelectedToLeft();
    }
  };

  handleRight = ({ ctrlKey }) => {
    const { isModalMode, acSquaresToRight, acSelectedToRight } = this.props;

    if (isModalMode) return;
    if (ctrlKey) {
      acSquaresToRight();
    } else {
      acSelectedToRight();
    }
  };

  handleUp = ({ ctrlKey }) => {
    const { isModalMode, acSquaresToUp, acSelectedToUp } = this.props;

    if (isModalMode) return;
    if (ctrlKey) {
      acSquaresToUp();
    } else {
      acSelectedToUp();
    }
  };

  handleDown = ({ ctrlKey }) => {
    const { isModalMode, acSquaresToDown, acSelectedToDown } = this.props;

    if (isModalMode) return;
    if (ctrlKey) {
      acSquaresToDown();
    } else {
      acSelectedToDown();
    }
  };

  handleRedo = () => {
    const { isModalMode, acRedoAction } = this.props;

    if (isModalMode) return;
    acRedoAction();
  };

  handleUndo = () => {
    const { isModalMode, acUndoAction } = this.props;

    if (isModalMode) return;
    acUndoAction();
  };

  render() {
    return null;
  }
}

Controller.propTypes = {
  isModalMode: PropTypes.bool.isRequired,
  acSelectedToLeft: PropTypes.func.isRequired,
  acSelectedToRight: PropTypes.func.isRequired,
  acSelectedToUp: PropTypes.func.isRequired,
  acSelectedToDown: PropTypes.func.isRequired,
  acSquaresToLeft: PropTypes.func.isRequired,
  acSquaresToRight: PropTypes.func.isRequired,
  acSquaresToUp: PropTypes.func.isRequired,
  acSquaresToDown: PropTypes.func.isRequired,
  acUndoAction: PropTypes.func.isRequired,
  acRedoAction: PropTypes.func.isRequired,
};

export default Controller;
