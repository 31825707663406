import squaresToMatrix from './plates-to-matrix';

const addAction = state =>
  state.actions.slice().concat([
    {
      selectedValue: state.selectedValue,
      matrix: squaresToMatrix(state.squares),
    },
  ]);

export default addAction;
