import { connect } from 'react-redux';

import Field from './Field';
import {
  getIsCurrentGameCompleted,
  getMatrix,
  getSelectedValue,
  getSquares,
} from '../../../../store/selectors';
import { acMoveBySelection, acSetSquares } from '../../../../store/actions';

const mapStateToProps = state => ({
  matrix: getMatrix(state),
  selectedValue: getSelectedValue(state),
  squares: getSquares(state),
  isGameCompleted: getIsCurrentGameCompleted(state),
});

const mapDispatchToProps = {
  setSquares: acSetSquares,
  moveSquares: acMoveBySelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Field);
