import {
  findSquareByProps,
  findSquareIndexByProps,
} from './find-square-by-props';
import filterSquaresByProps from './filter-squares-by-props';
import getMovableValues from './get-movable-values';
import addAction from './add-action';
import { getIsCurrentGameCompleted } from '../../store/selectors';

const moveSquaresValue = (state, deltaX, deltaY) => {
  if (!getIsCurrentGameCompleted({ game: { model: state } })) {
    const squares = state.squares.slice();
    const { cellsInRow, selectedValue } = state;

    const selectedSquare = findSquareByProps(squares, {
      value: selectedValue,
    });

    // get group
    const fieldKey = deltaX !== 0 ? 'fieldY' : 'fieldX';
    const fieldValue = selectedSquare.props[fieldKey];
    const group = filterSquaresByProps(squares, { [fieldKey]: fieldValue });

    // get movable values
    const delta = deltaX !== 0 ? deltaX : deltaY;
    const field = deltaX !== 0 ? 'fieldX' : 'fieldY';
    const movableValues = getMovableValues(
      selectedSquare,
      group,
      field,
      delta,
      cellsInRow,
    );

    // change props in movable plates in plates
    movableValues.forEach(value => {
      const i = findSquareIndexByProps(squares, { value });
      const patch = {};
      patch[field] = squares[i].props[field] + delta;
      squares[i].setProps(patch);
    });

    /* const gameMode =
     state.layoutType === LAYOUT_TYPE.MIXED &&
     getTypeOfLayout(platesToMatrix(state.plates)) === LAYOUT_TYPE.IN_ORDER
     ? GAME_MODES.COMPLETED
     : state.gameMode; */

    return {
      ...state,
      squares,
      actions: movableValues.length > 0 ? addAction(state) : state.actions,
      canceledActions: [],
      // gameMode
    };
  }

  return state;
};

export default moveSquaresValue;
