import {
  // AC_ENTITY_SHOULD_BE_LOADED,
  AC_ENTITY_LOADING_START,
  AC_ENTITY_LOADING_ERROR,
  AC_ENTITY_LOADING_SUCCESS,
  AC_ENTITY_SAVE_START,
  AC_ENTITY_SAVE_ERROR,
  AC_ENTITY_SAVE_SUCCESS,
} from '../action-types';
import { ENTITY_ID } from '../../../constants';
import { MESSAGES_TYPE } from '../../../core/constants';
import { getActions, getCellsInRow } from '../../selectors';
import { getSessionToken } from '../../../core/store/selectors';
import { acLogout, acAddMessage } from '../../../core/store/actions';
import { acResetApp } from '../../../core/store/actions/creators/common';
import prepareToSave from '../../../utils/store/prepare-to-save';
import toErrorMessageObject from '../../../core/utils/to-error-message-object';
import { acSetQuickLoadedToState } from './field-actions';

const { CURRENT_GAME, GAMES, GALLERY } = ENTITY_ID;

// export const acEntityShouldBeLoaded = entityId => ({
//   type: AC_ENTITY_SHOULD_BE_LOADED,
//   payload: { entityId },
// });

export const acEntityLoadingStart = entityId => ({
  type: AC_ENTITY_LOADING_START,
  payload: { entityId },
});

export const acEntityLoadingError = (entityId, error) => ({
  type: AC_ENTITY_LOADING_ERROR,
  payload: { entityId, error },
});

export const acEntityLoadingSuccess = (entityId, data) => ({
  type: AC_ENTITY_LOADING_SUCCESS,
  payload: { entityId, data },
});

export const acEntitySaveStart = entityId => ({
  type: AC_ENTITY_SAVE_START,
  payload: { entityId },
});

export const acEntitySaveError = (entityId, error) => ({
  type: AC_ENTITY_SAVE_ERROR,
  payload: { entityId, error },
});

export const acEntitySaveSuccess = (entityId, data) => ({
  type: AC_ENTITY_SAVE_SUCCESS,
  payload: { entityId, data },
});

const getData = {
  [GALLERY]: (api, state) => {
    const cellsInRow = getCellsInRow(state);
    const sessionToken = getSessionToken(state);

    return api.game.gallery.getGallery(cellsInRow, sessionToken);
  },
  [GAMES]: (api, state) => {
    const actions = getActions(state);
    const sessionToken = getSessionToken(state);

    return api.game.games.getGamesByCurrentLayout(actions, sessionToken);
  },
};

const loadEntity = entityId => () => async (dispatch, getState, api) => {
  try {
    dispatch(acEntityLoadingStart(entityId));

    const state = getState();
    const data = await getData[entityId](api, state);

    dispatch(acEntityLoadingSuccess(entityId, data));
  } catch (err) {
    dispatch(acEntityLoadingError(entityId, err));
    if (err?.status === 401) {
      dispatch(acLogout());
    }
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};

export const loadGallery = loadEntity(GALLERY);

export const loadGames = loadEntity(GAMES);

/* const saveData = {
  [GAMES]: (api, state, isQuickSave = false) => {
    const sessionToken = getSessionToken(state);
    const data = prepareToSave(getActions(state));

    return api.game.games.saveGame(data, sessionToken, isQuickSave);
  },
}; */

const saveGame = isQuickSave => () => async (dispatch, getState, api) => {
  try {
    dispatch(acEntitySaveStart(GAMES));

    const state = getState();
    const sessionToken = getSessionToken(state);
    const data = prepareToSave(getActions(state));

    await api.game.games.saveGame(data, sessionToken, isQuickSave);

    dispatch(acEntitySaveSuccess(GAMES));
    dispatch(
      acAddMessage({
        message: 'Saved successfully',
        status: '',
        date: new Date().toLocaleString(),
        type: MESSAGES_TYPE.SUCCESS,
      }),
    );
  } catch (err) {
    dispatch(acEntitySaveError(GAMES, err));
    if (err?.status === 401) {
      dispatch(acLogout());
      dispatch(acResetApp());
    }
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};

export const quickLoadCurrentGame = () => async (dispatch, getState, api) => {
  try {
    dispatch(acEntityLoadingStart(CURRENT_GAME));

    const state = getState();
    const cellsInRow = getCellsInRow(state);
    const sessionToken = getSessionToken(state);

    const result = await api.game.games.getQuickSavedGame(
      cellsInRow,
      sessionToken,
    );

    const { start, movements } = result;

    dispatch(acEntityLoadingSuccess(CURRENT_GAME));
    dispatch(acSetQuickLoadedToState(start, movements));
  } catch (err) {
    dispatch(acEntityLoadingError(CURRENT_GAME, err));
    if (err?.status === 401) {
      dispatch(acLogout());
    }
    dispatch(acAddMessage(toErrorMessageObject(err)));
  }
};

export const loadGamesByCurrentLayout = loadEntity(GAMES);

export const saveCurrentGame = saveGame(false);
export const quickSaveCurrentGame = saveGame(true);
