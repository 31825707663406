const API = '/api/fifteen/v1';

const URLS = {
  GET_GALLERY: `${API}/games/size/#{cellsInRow}`,
  GET_GAMES_BY_HASH: '/api/fifteen/v1/games/hash/#{hashBase64}',
  SAVE_GAME: `${API}/game`,
  QUICK_SAVE: `${API}/game/quick-save`,
  QUICK_LOAD: `${API}/game/quick-load?size={cellsInRow}`,
};

export default URLS;
