import { getLast } from '../../core/utils/common-utils';
import doLastAction from './do-last-action';
import { getIsCurrentGameCompleted } from '../../store/selectors';

const undoAction = state => {
  if (!getIsCurrentGameCompleted({ game: { model: state } })) {
    let { actions } = state;
    const { canceledActions, squares } = state;

    if (actions.length > 1) {
      const canceled = getLast(actions);
      actions = actions.slice(0, actions.length - 1);
      canceledActions.push(canceled);

      return {
        ...state,
        ...doLastAction(actions, squares),
        canceledActions,
        actions,
      };
    }
  }

  return state;
};

export default undoAction;
