import { findSquareByProps } from './find-square-by-props';

const squaresToMatrix = squares => {
  const cellsInRow = Math.sqrt(squares.length + 1);

  return Array(cellsInRow ** 2)
    .fill(0)
    .map((value, i) => {
      const fieldY = Math.floor(i / cellsInRow);
      const fieldX = i - fieldY * cellsInRow;
      const square = findSquareByProps(squares, { fieldX, fieldY });

      return square ? square.props.value : value;
    });
};

export default squaresToMatrix;
