import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './shared/MenuItemButton';
import Link from './shared/MenuItemLink';
import List from '../List';
import ListItem from '../List/shared/ListItem';
import MenuDivider from './shared/MenuDivider';
import { isFilledArray } from '../../utils/common-utils';

import styles from './Menu.module.scss';

const Menu = ({ className, options, ...rest }) => {
  const ownClassName = classNames(styles['menu'], className && className);

  const items =
    isFilledArray(options) &&
    options
      .map(({ id, isVisible, label, url, onClick, ...restItemProps }, i) => {
        if (id === 'divider') {
          // eslint-disable-next-line react/no-array-index-key
          return <MenuDivider key={`divider-${i}`} {...restItemProps} />;
        }

        const actionProps = { href: url, onClick };
        let ChildComponent = childProps => (
          <div {...childProps} className={styles['menu-item__child']} />
        );
        if (url) {
          ChildComponent = Link;
        } else if (onClick) {
          ChildComponent = Button;
        }

        return (
          isVisible !== false && (
            <ListItem
              key={id}
              className={styles['menu-item']}
              {...restItemProps}
            >
              <ChildComponent {...actionProps}>{label}</ChildComponent>
            </ListItem>
          )
        );
      })
      .filter(Boolean);

  return (
    <div className={ownClassName}>
      <List {...rest}>{items}</List>
    </div>
  );
};

Menu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

Menu.defaultProps = {
  options: [],
  className: '',
};

export default Menu;
