export const AC_CHANGE_CELLS_IN_ROW = 'AC_CHANGE_CELLS_IN_ROW';

export const AC_SET_LAYOUT = 'AC_SET_LAYOUT';
export const AC_RESET_CURRENT_LAYOUT = 'AC_RESET_CURRENT_LAYOUT';

export const AC_SET_SQUARES = 'AC_SET_SQUARES';

export const AC_SET_QUICK_LOADED_TO_STATE = 'AC_SET_QUICK_LOADED_TO_STATE';

export const AC_MOVE_BY_SELECTION = 'AC_MOVE_BY_SELECTION';

export const AC_SELECTED_TO_LEFT = 'AC_SELECTED_TO_LEFT';
export const AC_SELECTED_TO_RIGHT = 'AC_SELECTED_TO_RIGHT';
export const AC_SELECTED_TO_UP = 'AC_SELECTED_TO_UP';
export const AC_SELECTED_TO_DOWN = 'AC_SELECTED_TO_DOWN';
export const AC_SQUARES_TO_LEFT = 'AC_SQUARES_TO_LEFT';
export const AC_SQUARES_TO_RIGHT = 'AC_SQUARES_TO_RIGHT';
export const AC_SQUARES_TO_UP = 'AC_SQUARES_TO_UP';
export const AC_SQUARES_TO_DOWN = 'AC_SQUARES_TO_DOWN';
export const AC_UNDO_ACTION = 'AC_UNDO_ACTION';
export const AC_REDO_ACTION = 'AC_REDO_ACTION';

export const AC_ENTITY_SHOULD_BE_LOADED = 'AC_ENTITY_SHOULD_BE_LOADED';
export const AC_ENTITY_LOADING_START = 'AC_ENTITY_LOADING_START';
export const AC_ENTITY_LOADING_ERROR = 'AC_ENTITY_LOADING_ERROR';
export const AC_ENTITY_LOADING_SUCCESS = 'AC_ENTITY_LOADING_SUCCESS';
export const AC_ENTITY_SAVE_START = 'AC_ENTITY_SAVE_START';
export const AC_ENTITY_SAVE_ERROR = 'AC_ENTITY_SAVE_ERROR';
export const AC_ENTITY_SAVE_SUCCESS = 'AC_ENTITY_SAVE_SUCCESS';
