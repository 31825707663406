import moveSelectedValue from './move-selected-value';
import { getIsCurrentGameCompleted } from '../../store/selectors';

const moveSelectedItem = (state, deltaX, deltaY) => {
  if (!getIsCurrentGameCompleted({ game: { model: state } })) {
    return {
      ...state,
      selectedValue: moveSelectedValue(state, deltaX, deltaY),
    };
  }

  return state;
};

export default moveSelectedItem;
