import createRandomLayout from './create-random-layout';
import validateLayout from './validate-layout';
import { getLast } from '../../core/utils/common-utils';

/**
 * Returns random solvable layout
 * @param {number} cellsInRow
 * @returns {number[]} Solvable layout
 */
const getValidLayout = cellsInRow => {
  const layout = createRandomLayout(cellsInRow);

  while (!validateLayout(layout)) {
    const i = layout.indexOf(0);
    layout.splice(i, 1);
    const lastIndex = layout.length - 1;
    const beforeLastIndex = layout.length - 2;
    const last = getLast(layout);
    layout[lastIndex] = layout[beforeLastIndex];
    layout[beforeLastIndex] = last;
    layout.splice(i, 0, 0);
  }

  return layout;
};

export default getValidLayout;
