import { parseResponse, get } from '../core/utils/network-requests';
import { URLS } from '../constants';

const getGallery = async (cellsInRow, sessionToken) => {
  const url = URLS.GET_GALLERY.replace('#{cellsInRow}', cellsInRow);

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const galleryApi = {
  getGallery,
};

export default galleryApi;
