import correctCoordinate from './correct-coordinate';
import { findSquareByProps } from './find-square-by-props';

const moveSelectedValue = (state, deltaX, deltaY) => {
  const { selectedValue, cellsInRow } = state;
  let square = findSquareByProps(state.squares, { value: selectedValue });
  let x = square.props.fieldX;
  let y = square.props.fieldY;
  do {
    x = correctCoordinate(x + deltaX, false, cellsInRow);
    y = correctCoordinate(y + deltaY, false, cellsInRow);
    square = findSquareByProps(state.squares, { fieldX: x, fieldY: y });
  } while (!square);

  return square.props.value;
};

export default moveSelectedValue;
