import { connect } from 'react-redux';

import Controller from './Controller';
import { getIsModalMode } from '../../core/store/selectors';
import {
  acSelectedToLeft,
  acSelectedToRight,
  acSelectedToUp,
  acSelectedToDown,
  acSquaresToLeft,
  acSquaresToRight,
  acSquaresToUp,
  acSquaresToDown,
  acUndoAction,
  acRedoAction,
} from '../../store/actions';

const mapStateToProps = state => ({
  isModalMode: getIsModalMode(state),
});

const mapDispatchToProps = {
  acSelectedToLeft,
  acSelectedToRight,
  acSelectedToUp,
  acSelectedToDown,
  acSquaresToLeft,
  acSquaresToRight,
  acSquaresToUp,
  acSquaresToDown,
  acUndoAction,
  acRedoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
