import getChildrenByParentId from '../../../../../../utils/get-children-by-parent-id';
import { URLS } from '../../../../../../constants';
import goToPageWithAuthParams from '../../../../../../utils/go-to-page-with-auth-params';
import coreConfig from '../../../../../../../.corerc';

const { FEATURE_BY_HOST } = URLS;

const { getMainMenuOptions: getGameOptions } = coreConfig;

/**
 * Fields of props
 * @property {String} parentId
 * @property {Boolean} gameOptionsProps.isLogged
 * @property {function} gameOptionsProps.closeAllModals
 * @property {function} gameOptionsProps.openModal
 * @property {function} gameOptionsProps.goTo
 * @property {function} gameOptionsProps.handleLinkClick
 * ...other gameOptionsProps
 */
const getAppMenuOptions = ({ parentId, ...gameOptionsProps }) => {
  const options = [
    {
      id: 'feature.by',
      label: 'Go to feature.by',
      url: FEATURE_BY_HOST,
      onClick: goToPageWithAuthParams(FEATURE_BY_HOST),
    },
    {
      id: 'divider',
      className: '',
    },
    ...getGameOptions(gameOptionsProps),
  ];

  return getChildrenByParentId(options, parentId);
};

export default getAppMenuOptions;
