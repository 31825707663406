import { get, parseResponse, post } from '../core/utils/network-requests';
import { isFilledArray } from '../core/utils/common-utils';
import { getHashFromLayout } from '../utils/store/layout-hash-transformation';
import { URLS } from '../constants';

const saveGame = async (data, sessionToken, isQuickSave = false) => {
  const url = isQuickSave ? URLS.QUICK_SAVE : URLS.SAVE_GAME;

  const response = await post({
    url,
    headers: {
      'session-token': sessionToken,
      'Content-Type': 'application/json',
    },
    body: data,
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const getQuickSavedGame = async (cellsInRow, sessionToken) => {
  const url = URLS.QUICK_LOAD.replace('{cellsInRow}', cellsInRow);

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const getGamesByCurrentLayout = async (actions, sessionToken) => {
  const layout = isFilledArray(actions) ? actions[0].matrix : [];
  const hashBase64 = btoa(getHashFromLayout(layout));

  const url = URLS.GET_GAMES_BY_HASH.replace('#{hashBase64}', hashBase64);

  const response = await get({
    url,
    headers: {
      'session-token': sessionToken,
    },
  });

  const parsedResponse = await parseResponse(response);

  if (!response.ok) {
    throw parsedResponse;
  }

  return parsedResponse;
};

const gamesApi = {
  getGamesByCurrentLayout,
  getQuickSavedGame,
  saveGame,
};

export default gamesApi;
